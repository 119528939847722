import { createStore } from "vuex"

import auth from "./modules/auth"
import content from "./modules/content"
import dynamicFormsLead from "./modules/dynamic-forms/leads"
import dynamicFormsAdministration from "./modules/dynamic-forms/administration"
import dynamicFormsEnedisConsumptionPermission from "./modules/dynamic-forms/enedis-consumption-permission"
import installerDirectory from "./modules/installer-directory"
import hardwareManufacturers from "./modules/hardware/manufacturers"
import leads from "./modules/leads"
import newsletters from "./modules/newsletter"
import jobListings from "./modules/job-listings"
import products from "./modules/products"

const debugging = process.env.NODE_ENV !== "production"

export default createStore({
  state: {},
  modules: {
    auth,
    leads,
    dynamicFormsLead,
    dynamicFormsAdministration,
    dynamicFormsEnedisConsumptionPermission,
    content,
    installerDirectory,
    jobListings,
    hardwareManufacturers,
    newsletters,
    products,
  },
  strict: debugging,
})
