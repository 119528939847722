import { unionState } from "@common/js/store/helpers"

const state = () => ({
  installers: [],
  departments: [],
  items: [],
})

const actions = {
  fetch({ commit }, { id }) {
    return axios.get(`/installer-directory/${id}`).then((response) => {
      commit("storeItem", response.data)
    })
  },
  search({ commit }, queryParams) {
    const queryString = new URLSearchParams(queryParams).toString()
    return axios
      .get(`/installer-directory/search?${queryString}`)
      .then((response) => {
        commit("storeInstallers", response.data.items)
      })
  },
  fetchDepartmentCount({ commit }, params) {
    return axios
      .get(`/installer-directory/department-count`)
      .then((response) => {
        commit("storeDepartments", response.data)
      })
  },
  register({ commit }, {params, type, slug}) {
    return axios.post(`installer-hosted-pages/${slug}/onboard`, params).then((response) => {
      console.log('response: ', response);
    })
  }
}

const mutations = {
  storeInstallers(state, installers) {
    state.installers = [...installers]
  },
  storeItem(state, item) {
    state.items = unionState(state.items, [item])
  },
  storeDepartments(state, departments) {
    state.departments = [...departments]
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
