import { unionState } from "@common/js/store/helpers"

const state = () => ({
  items: [],
})

const actions = {
  fetchAll({ commit }) {
    return axios.get(`/hardware/manufacturers`).then((response) => {
      // to change later
      commit("storeItems", response.data.slice(0, 10))
      return response.data.slice(0, 10)
    })
  },

  async fetchBySlug({ commit }, slug) {
    const response = await axios.get(`/hardware/manufacturers/search`, {
      params: { slug },
    })
    commit("storeItems", response.data.items)
  },
}

const mutations = {
  storeItem(state, item) {
    state.items = unionState(state.items, [item])
  },

  storeItems(state, items) {
    state.items = unionState(state.items, items)
  },
}

const getters = {
  getById: (state) => (id) => state.items.find((p) => p.id === id),
  getBySlug: (state) => (slug) => state.items.find((p) => p.slug === slug),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
